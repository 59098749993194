<template>
  <v-text-field
    v-model="innerValue"
    v-bind="$attrs"
    v-on="$listeners"
    :name="randomName"
    :id="randomName"
    :autocomplete="randomName"
    :tabindex="tabindex || 1"
    @blur="updateValue"
  ></v-text-field>
</template>

<script>
export default {
  props: ["value", "fixedId", "tabindex"],
  data() {
    return {
      innerValue: null,
      randomName: "",
    };
  },
  watch: {
    value: {
      handler(v) {
        this.innerValue = v;
      },
      immediate: true,
    },
    fixedId: {
      handler(v) {
        if (v === null || v === undefined) {
          this.randomName = v;
        } else {
          this.randomName = this.getRandomName();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.fixedId === null || this.fixedId === undefined) {
      this.randomName = this.getRandomName();
    } else {
      this.randomName = this.fixedId;
    }
  },
  methods: {
    updateValue() {
      if (this.innerValue !== undefined && this.innerValue !== null) {
        this.innerValue = this.innerValue.toString().trim();
      }
      this.$emit("update:value", this.innerValue);
    },
    getRandomName() {
      return Math.random().toString();
    },
  },
};
</script>
