<template>
  <material-card-sba
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :height.sync="internalHeight"
    :maxOrderValue="maxOrderValue"
    color="info"
    :widgetIcon="widgetIcon"
    :title="$t('components.vue_material_dashboard.items.documents.title')"
  >
    <v-row no-gutters class="fill-height mx-6" justify="end">
      <v-card-text class="text-left pb-0">
        <v-list class="mt-4">
          <v-list-item-group v-model="selectedItem">
            <div v-show="!loading">
              <v-divider></v-divider>
              <template v-for="(doc, i) in latest3Documents">
                <v-list-item class="px-0" :key="'doc-' + i">
                  <v-list-item-icon class="my-1 document">
                    <v-icon size="50" :color="doc.CategoryIconColor">{{
                      doc.CategoryIcon
                    }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title @click="goToDocument(doc)">
                      {{
                        doc
                          ? doc["Title" + $vuetify.lang.current.toUpperCase()]
                          : ""
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <!-- <v-list-item-icon class="pt-2">
                    <v-icon @click.stop="printFile" small>mdi-printer</v-icon>
                  </v-list-item-icon>
                  <v-list-item-icon class="pt-2 ml-0">
                    <v-icon class="" @click.stop="downloadFile" small
                      >mdi-download</v-icon
                    >
                  </v-list-item-icon> -->
                </v-list-item>
                <v-divider :key="i + '-divider'"></v-divider>
              </template>
            </div>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-btn
        color="info"
        class="action-btn"
        :to="role === 'Trustee' ? 'download-centre-new' : 'download-centre'"
        >{{ $t("globals.more") }}</v-btn
      >
      <v-progress-circular
        v-show="loading"
        size="50"
        class="loader"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-row>
  </material-card-sba>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MaterialCardSba from "@/components/VueMaterialDashboard/material/MaterialCardSbaDashboard.vue";
export default {
  components: {
    MaterialCardSba,
  },
  props: {
    widgetId: String,
    widgetIcon: String,
    size: Number,
    height: Number,
    order: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
      internalHeight: undefined,
      selectedItem: undefined,
      downloadsData: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "role"]),
    ...mapGetters("common", ["downloadCentreData", "userProfile"]),
    currentBenefits() {
      let memberBenefits = this.userProfile?.Coverages.filter(
        (c) => !c.IsWaived
      );
      return memberBenefits.map((c) => c?.CurrentBenefit?.Name);
    },
    latest3Documents() {
      const documents = JSON.parse(JSON.stringify(this.downloadCentreData));
      const sortedByDate = documents.sort(function (a, b) {
        return new Date(b.DateUploaded) - new Date(a.DateUploaded);
      });
      if (this.role === "Trustee")
        return sortedByDate
          .filter((d) => d.MemberTypes.includes("Trustee"))
          .slice(0, 3);
      let filteredItems = sortedByDate.filter((i) => {
        const includeBenefits = i.IncludeBenefits || [];
        const excludeBenefits = i.ExcludeBenefits || [];
        return (
          (i.MemberTypes?.includes(this.userProfile.MemberGroup?.Name) ||
            i.MemberTypes?.includes("All")) &&
          (includeBenefits?.length === 0 ||
            includeBenefits?.some((b) => this.currentBenefits.includes(b))) &&
          (excludeBenefits?.length === 0 ||
            !excludeBenefits?.some((b) => this.currentBenefits.includes(b)))
        );
      });
      return filteredItems.slice(0, 3);
    },
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.height !== null && this.height !== undefined) {
      this.internalHeight = this.height;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
  },
  methods: {
    ...mapActions("common", ["getDownloadCentreData"]),
    goToDocument(doc) {
      this.$router.push({
        name: "downloadCentre",
        params: {
          doc: doc,
        },
      });
    },
    printFile() {
      console.log("Print file");
    },
    downloadFile() {
      console.log("Download file");
    },
  },
};
</script>
<style scoped>
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.action-btn {
  position: absolute;
  bottom: 24px;
  right: 24px;
}
</style>
