<template>
  <div>
    <v-list dense class="results-list">
      <!-- MenuItems -->
      <v-subheader
        v-if="foundMenuItems.length"
        class="text-subtitle-2 font-weight-bold primary--text"
        >{{ $t("components.dashboard.search.pages") }}</v-subheader
      >
      <v-list-item-group v-if="foundMenuItems.length">
        <v-list-item
          class="text-subtitle-2 text-left"
          v-for="(item, i) in foundMenuItems"
          :key="'page' + i"
          @click="openMenuItem(item)"
        >
          {{ $vuetify.lang.current === "en" ? item.TitleEN : item.TitleFR }}
        </v-list-item>
      </v-list-item-group>
      <!-- Downloads -->
      <v-subheader
        v-if="foundDownloads.length"
        class="text-subtitle-2 font-weight-bold primary--text"
        >{{ $t("components.dashboard.search.documents") }}</v-subheader
      >
      <v-list-item-group v-if="foundDownloads.length">
        <v-list-item
          class="text-subtitle-2 text-left"
          v-for="(item, i) in foundDownloads"
          :key="'doc' + i"
          @click="$emit('view-pdf', item)"
        >
          {{ $vuetify.lang.current === "en" ? item.TitleEN : item.TitleFR }}
        </v-list-item>
      </v-list-item-group>
      <!-- LifeEvents -->
      <v-subheader
        v-if="
          foundLifeEvents.length ||
          foundLifeCategories.length ||
          foundLifeAnswers.length
        "
        class="text-subtitle-2 font-weight-bold primary--text"
        >{{ $t("components.dashboard.search.life_events") }}</v-subheader
      >
      <v-list-item-group v-if="foundLifeCategories.length">
        <v-list-item
          class="text-subtitle-2 text-left"
          v-for="(item, i) in foundLifeCategories"
          :key="'le-cat' + i"
          @click="openSelectedLifeCategory(item)"
        >
          {{
            $vuetify.lang.current === "en" ? item.CategoryEN : item.CategoryFR
          }}
        </v-list-item>
      </v-list-item-group>
      <v-list-item-group v-if="foundLifeEvents.length">
        <v-list-item
          class="text-subtitle-2 text-left"
          v-for="(item, i) in foundLifeEvents"
          :key="'le' + i"
          @click="openSelectedLifeEvent(item)"
        >
          {{
            $vuetify.lang.current === "en" ? item.QuestionEN : item.QuestionFR
          }}
        </v-list-item>
      </v-list-item-group>
      <v-list-item-group v-if="foundLifeAnswers.length">
        <v-list-item
          class="text-subtitle-2 text-left"
          v-for="(item, i) in foundLifeAnswers"
          :key="'le' + i"
          @click="openSelectedLifeEvent(item)"
        >
          <v-list-item-content class="py-0">
            <v-list-item-title class="text-decoration-underline">
              {{
                $vuetify.lang.current === "en"
                  ? item.QuestionEN
                  : item.QuestionFR
              }}
            </v-list-item-title>
            <v-list-item-subtitle style="max-height: 20px">
              <span
                class="elipsis-text"
                v-html="
                  $vuetify.lang.current === 'en' ? item.AnswerEN : item.AnswerFR
                "
              ></span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <!-- FAQ -->
      <v-subheader
        v-if="foundFaqs.length"
        class="text-subtitle-2 font-weight-bold primary--text"
        >{{ $t("components.dashboard.search.faq") }}</v-subheader
      >
      <v-list-item-group v-if="foundFaqs.length">
        <v-list-item
          class="text-subtitle-2 text-left"
          v-for="(item, i) in foundFaqs"
          :key="'faq' + i"
          @click="openSelectedFaq(item)"
        >
          {{
            $vuetify.lang.current === "en" ? item.QuestionEN : item.QuestionFR
          }}
        </v-list-item>
      </v-list-item-group>
      <v-list-item-group v-if="!isSearchFound">
        <v-list-item class="text-subtitle-2 text-left">
          {{ $t("components.dashboard.search.no_results") }}
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    search: String,
    triggerSearch: Boolean,
  },
  data() {
    return {
      foundMenuItems: [],
      foundLifeEvents: [],
      foundLifeCategories: [],
      foundLifeAnswers: [],
      foundFaqs: [],
      foundDownloads: [],
      trigger: 0,
      lifeEventsAllItems: [],
      leQuestionItems: [],
    };
  },
  watch: {
    triggerSearch: {
      handler() {
        this.searchQuestion();
      },
    },
  },
  computed: {
    ...mapGetters("common", [
      "leftDrawerMenuItems",
      "faq",
      "lifeEvents",
      "downloadCentreData",
      "userProfile",
      "isPensionsPortal",
    ]),
    isSearchFound() {
      return (
        this.foundMenuItems.length +
        this.foundLifeEvents.length +
        this.foundLifeCategories.length +
        this.foundFaqs.length +
        this.foundDownloads.length +
        this.foundLifeAnswers.length
      );
    },
    currentBenefits() {
      let memberBenefits = this.userProfile?.Coverages.filter(
        (c) => !c.IsWaived
      );
      return memberBenefits.map((c) => c?.CurrentBenefit?.Name);
    },
  },
  mounted() {
    let eventsItems = [];
    if (this.lifeEvents) {
      const objKeys = Object.keys(this.lifeEvents);
      for (let key of objKeys) {
        eventsItems = [...eventsItems, ...this.lifeEvents[key]];
        eventsItems.forEach((e) => (e.key = key));
        for (let category of this.lifeEvents[key]) {
          for (let quiz of category.Questions) {
            quiz.CategoryId = category.CategoryId;
            quiz.key = key;
            this.leQuestionItems.push(quiz);
          }
        }
      }
    }
    this.lifeEventsAllItems = eventsItems;
  },
  methods: {
    searchQuestion() {
      if (!this.search) {
        this.foundMenuItems = [];
        this.foundLifeEvents = [];
        this.foundLifeCategories = [];
        this.foundLifeAnswers = [];
        this.foundFaqs = [];
        this.foundDownloads = [];
        this.$emit("search-found", 0);
        this.trigger = 0;
        return;
      }
      const searchWords = this.search.split(" ");
      let foundSubMenus = [];
      let foundMenuItems = [];
      let foundLifeEvents = [];
      let foundLifeCategories = [];
      let foundLifeAnswers = [];
      let foundFaqs = [];
      let foundDownloads = [];
      let menuItems = {};
      let subMenuItems = {};
      let faqItems = {};
      let lifeEventItems = {};
      let lifeEventCategoryItems = {};
      let lifeEventAnswerItems = {};
      let downloadCentreDataItems = {};

      for (let word of searchWords) {
        if (word.length < 3) continue;
        if (this.leftDrawerMenuItems) {
          const result = this.leftDrawerMenuItems.filter((i) => {
            if (i.SubMenus) {
              const foundInSubMenus = i.SubMenus.filter((s) => {
                return this.$vuetify.lang.current === "en"
                  ? s.TitleEN.toLowerCase().includes(word.toLowerCase())
                  : s.TitleFR.toLowerCase().includes(word.toLowerCase());
              });
              if (foundInSubMenus.length) {
                if (foundSubMenus.length) {
                  foundSubMenus = [...foundSubMenus, ...foundInSubMenus];
                } else foundSubMenus = foundInSubMenus;
              }
            }
            // console.log(
            //   "keywords:",
            //   i.KeywordsEN,
            //   i?.KeywordsEN?.toLowerCase().includes(word.toLowerCase())
            // );
            return this.$vuetify.lang.current === "en"
              ? i.TitleEN.toLowerCase().includes(word.toLowerCase()) ||
                  i?.KeywordsEN?.toLowerCase().includes(word.toLowerCase())
              : i.TitleFR.toLowerCase().includes(word.toLowerCase()) ||
                  i?.KeywordsFR?.toLowerCase().includes(word.toLowerCase());
          });
          if (foundSubMenus.length) {
            foundSubMenus.forEach((i) => (subMenuItems[i.TitleEN] = i));
          }
          if (result.length) {
            foundMenuItems = [...foundMenuItems, ...result];
            foundMenuItems.forEach((i) => (menuItems[i.TitleEN] = i));
          }
        }

        if (this.faq) {
          let memberTypeKeyword = "";
          if (this.isPensionsPortal) {
            memberTypeKeyword = this.userProfile?.AccountStatus;
          } else {
            memberTypeKeyword = this.userProfile?.MemberGroup?.Name;
          }
          const memberGroupFaqs = this.faq.filter(
            (i) =>
              i.MemberTypes.includes(memberTypeKeyword) ||
              i.MemberTypes.includes("All")
          );
          const result = memberGroupFaqs.filter((i) => {
            return this.$vuetify.lang.current === "en"
              ? i.QuestionEN.toLowerCase().includes(word.toLowerCase())
              : i.QuestionFR.toLowerCase().includes(word.toLowerCase());
          });
          if (result.length) {
            foundFaqs = [...foundFaqs, ...result];
            foundFaqs.forEach((i) => (faqItems[i.QuestionEN] = i));
          }
        }

        if (this.lifeEventsAllItems) {
          const result = this.leQuestionItems.filter((i) => {
            return this.$vuetify.lang.current === "en"
              ? i.QuestionEN.toLowerCase().includes(word.toLowerCase())
              : i.QuestionFR.toLowerCase().includes(word.toLowerCase());
          });
          if (result.length) {
            foundLifeEvents = [...foundLifeEvents, ...result];
            foundLifeEvents.forEach((i) => (lifeEventItems[i.QuestionEN] = i));
          }

          const leAnswersResult = this.leQuestionItems.filter((i) => {
            return this.$vuetify.lang.current === "en"
              ? i.AnswerEN.toLowerCase().includes(word.toLowerCase())
              : i.AnswerFR.toLowerCase().includes(word.toLowerCase());
          });
          if (leAnswersResult.length) {
            foundLifeAnswers = [...foundLifeAnswers, ...leAnswersResult];
            foundLifeAnswers.forEach(
              (i) => (lifeEventAnswerItems[i.QuestionEN] = i)
            );
          }
          // const leGroupsResult = this.lifeEventsAllItems.filter((i) => {
          //   return this.$vuetify.lang.current === "en"
          //     ? i.GroupEN.toLowerCase().includes(word.toLowerCase())
          //     : i.GroupFR.toLowerCase().includes(word.toLowerCase());
          // });
          const leCategoriesResult = this.lifeEventsAllItems.filter((i) => {
            return this.$vuetify.lang.current === "en"
              ? i.CategoryEN.toLowerCase().includes(word.toLowerCase())
              : i.CategoryFR.toLowerCase().includes(word.toLowerCase());
          });
          const leCategoriesTextResult = this.lifeEventsAllItems.filter((i) => {
            return this.$vuetify.lang.current === "en"
              ? i.CategoryTextEN.toLowerCase().includes(word.toLowerCase())
              : i.CategoryTextEN.toLowerCase().includes(word.toLowerCase());
          });
          if (leCategoriesResult.length || leCategoriesTextResult.length) {
            // foundLifeCategories = [...foundLifeCategories];
            foundLifeCategories = [
              ...foundLifeCategories,
              ...leCategoriesResult,
              ...leCategoriesTextResult,
            ];
            foundLifeCategories.forEach(
              (i) => (lifeEventCategoryItems[i.CategoryEN] = i)
            );
          }
        }

        if (this.downloadCentreData) {
          const memberGroupDownloadsData = this.downloadCentreData.filter(
            (i) => {
              const includeBenefits = i.IncludeBenefits || [];
              const excludeBenefits = i.ExcludeBenefits || [];
              return (
                (i.MemberTypes?.includes(this.userProfile.MemberGroup?.Name) ||
                  i.MemberTypes?.includes("All")) &&
                (includeBenefits?.length === 0 ||
                  includeBenefits?.some((b) =>
                    this.currentBenefits.includes(b)
                  )) &&
                (excludeBenefits?.length === 0 ||
                  !excludeBenefits?.some((b) =>
                    this.currentBenefits.includes(b)
                  ))
              );
            }
          );
          const result = memberGroupDownloadsData.filter((i) => {
            return this.$vuetify.lang.current === "en"
              ? i.TitleEN.toLowerCase().includes(word.toLowerCase())
              : i.TitleFR.toLowerCase().includes(word.toLowerCase());
          });
          if (result.length) {
            foundDownloads = [...foundDownloads, ...result];
            foundDownloads.forEach(
              (i) => (downloadCentreDataItems[i.TitleEN] = i)
            );
          }
        }
      }

      this.foundMenuItems = [
        ...Object.values(menuItems),
        ...Object.values(subMenuItems),
      ];
      this.foundFaqs = Object.values(faqItems);
      this.foundLifeEvents = Object.values(lifeEventItems);
      this.foundLifeAnswers = Object.values(lifeEventAnswerItems);
      this.foundLifeCategories = Object.values(lifeEventCategoryItems);
      this.foundDownloads = Object.values(downloadCentreDataItems);
      if (this.isSearchFound) {
        this.trigger++;
        this.$emit("search-found", this.trigger);
      }
    },
    openMenuItem(item) {
      if (item.Popup) {
        this.$emit("open-popup", item.Popup);
      } else this.$router.push(item.Url);
      this.$emit("clear-search");
    },
    openSelectedLifeCategory(item) {
      console.log("open le category", item);
      this.$emit("clear-search");
      this.$router.push({
        path: `/life-event/${item.key}/category/${item.CategoryId}/question/1`,
      });
    },
    openSelectedLifeEvent(item) {
      console.log("open le category", item);
      this.$emit("clear-search");
      this.$router.push({
        path: `/life-event/${item.key}/category/${item.CategoryId}/question/${item.Id}`,
      });
    },
    openSelectedFaq(item) {
      this.$emit("clear-search");
      this.$router.push({
        name: "faq",
        params: {
          faq: item,
        },
      });
    },
  },
};
</script>
<style scoped>
.elipsis-text {
  flex: 1 1 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.results-list {
  max-width: 800px;
}
</style>
