<template>
  <v-container class="py-4">
    <v-form v-model="valid.address" v-if="!fetchedAddress">
      <v-row>
        <v-col cols="12" md="6" class="py-0" v-if="useCanadaPost">
          <v-select
            outlined
            :label="$t('components.address_expansion_panel.country')"
            v-model="selectedCountry"
            :items="enumModels.Country"
            :item-text="$vuetify.lang.current === 'en' ? 'textEn' : 'textFr'"
            item-value="value"
            :rules="[$rules.required]"
            @change="otherCountry = null"
            class="required"
            return-object
            clearable
          ></v-select>
        </v-col>
        <template v-if="selectedCountry && useCanadaPost">
          <v-col cols="12" md="6" class="py-0" v-if="selectedCountry.isOther">
            <v-autocomplete
              outlined
              clearable
              :items="countries"
              :label="$t('components.address_expansion_panel.other_country')"
              :rules="[$rules.required]"
              v-model="otherCountry"
              item-text="text"
              item-value="value"
              class="required errors-msg"
              @change="selectedAddress = null"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            :md="selectedCountry?.code === 'Other' ? 12 : 6"
            class="py-0 text--secondary pb-2"
            v-if="
              (selectedCountry.isOther && otherCountry !== null) ||
              !selectedCountry.isOther
            "
          >
            <v-autocomplete
              :label="
                $t(
                  'components.address_expansion_panel.start_typing_your_address'
                )
              "
              v-model="selectedAddress"
              :items="searchResults"
              return-object
              :loading="isAddressSearchLoading"
              :search-input.sync="searchValue"
              item-text="Description"
              item-value="Id"
              clearable
              append-outer-icon="mdi-magnify"
              ref="addressList"
            ></v-autocomplete>
            <small class="note-text">{{
              $t("components.address_expansion_panel.address_note")
            }}</small
            ><small class="note-text"
              >{{ " " }}
              <strong>{{
                $t("components.address_expansion_panel.address_note.example")
              }}</strong>
            </small>
          </v-col>
          <v-col cols="12" class="pt-0 pb-6" align-self="center">
            <span class="black--text">{{
              $t("components.address_expansion_panel.use_form")
            }}</span>
            <v-btn
              @click="
                useCanadaPost = false;
                updateValid();
              "
              text
              color="primary"
              class="link mb-1"
            >
              {{ $t("globals.here") }}
            </v-btn>
          </v-col>
        </template>

        <!-- manual enter address -->
        <template v-if="selectedCountry && !useCanadaPost">
          <v-row no-gutters align="center" class="py-2">
            <v-icon color="primary">mdi-pencil-outline</v-icon>
            <span class="text-title-1 ml-1 secondary--text">
              {{ $t("components.address_expansion_panel.enter_address") }}
            </span>
          </v-row>
          <v-row class="mt-1">
            <v-col cols="12" md="12" class="py-0">
              <v-select
                outlined
                :label="$t('components.address_expansion_panel.country')"
                v-model="selectedCountry"
                :items="enumModels.Country"
                :item-text="
                  $vuetify.lang.current === 'en' ? 'textEn' : 'textFr'
                "
                item-value="value"
                :rules="[$rules.required]"
                class="required"
                return-object
                clearable
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" lg="3" class="py-0">
              <no-autocomplete-textfield
                clearable
                outlined
                :label="$t('components.address_expansion_panel.apt_unit_suite')"
                v-model="internalMember.Address.SuiteNumber"
              ></no-autocomplete-textfield>
            </v-col>

            <v-col cols="12" md="3" lg="3" class="py-0">
              <no-autocomplete-textfield
                clearable
                outlined
                :label="$t('components.address_expansion_panel.street_number')"
                v-model="internalMember.Address.StreetNumber"
                :rules="[$rules.required, $rules.streetNumber]"
                class="required"
              ></no-autocomplete-textfield>
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <no-autocomplete-textfield
                clearable
                outlined
                :label="$t('components.address_expansion_panel.street_name')"
                v-model="internalMember.Address.StreetName"
                :rules="[$rules.required]"
                class="required"
              ></no-autocomplete-textfield>
            </v-col>

            <v-col cols="12" md="4" class="py-0">
              <no-autocomplete-textfield
                clearable
                outlined
                :label="$t('components.address_expansion_panel.city')"
                v-model="internalMember.Address.City"
                :rules="[$rules.required]"
                class="required"
              ></no-autocomplete-textfield>
            </v-col>

            <v-col
              cols="12"
              md="4"
              class="py-0"
              v-if="selectedCountry.code === 'CAN'"
            >
              <v-select
                outlined
                clearable
                :label="$t('components.address_expansion_panel.province')"
                v-model="internalMember.Address.Province"
                :items="enumModels.Province"
                :item-text="
                  $vuetify.lang.current === 'en' ? 'textEn' : 'textFr'
                "
                item-value="value"
                :rules="[$rules.required]"
                class="required"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              md="4"
              class="py-0"
              v-if="selectedCountry.code === 'USA'"
            >
              <v-autocomplete
                outlined
                clearable
                :items="states"
                :label="$t('components.address_expansion_panel.state')"
                :rules="[$rules.required]"
                v-model="internalMember.Address.OtherProvince"
                item-text="name"
                item-value="name"
                class="required errors-msg"
                @change="selectedAddress = null"
              ></v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              md="4"
              class="py-0"
              v-if="selectedCountry?.isOther"
            >
              <v-text-field
                clearable
                outlined
                :label="$t('components.address_expansion_panel.province')"
                v-model="internalMember.Address.OtherProvince"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="4"
              class="py-0"
              v-if="selectedCountry?.code == 'CAN'"
            >
              <no-autocomplete-textfield
                clearable
                outlined
                placeholder="A1A 1A1"
                v-mask="`A#A #A#`"
                :label="$t('components.address_expansion_panel.postal_code')"
                v-model="internalMember.Address.PostalCode"
                :rules="[$rules.required, $rules.postalCode]"
                class="required"
              ></no-autocomplete-textfield>
            </v-col>

            <v-col
              cols="12"
              md="4"
              class="py-0"
              v-if="selectedCountry?.code == 'USA'"
            >
              <no-autocomplete-textfield
                clearable
                outlined
                placeholder="23400"
                v-mask="`#####-####`"
                :label="$t('components.address_expansion_panel.zip_code')"
                v-model="internalMember.Address.OtherPostalCode"
                :rules="[$rules.required, $rules.zipCode]"
                class="required"
              ></no-autocomplete-textfield>
            </v-col>

            <v-col
              cols="12"
              md="6"
              lg="4"
              class="py-0"
              v-if="selectedCountry.isOther"
            >
              <v-autocomplete
                outlined
                clearable
                :items="countries"
                :label="$t('components.address_expansion_panel.country')"
                :rules="[$rules.required]"
                v-model="internalMember.Address.OtherCountry"
                item-text="text"
                item-value="value"
                class="required"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" align-self="center" class="py-0 mb-3">
              <span class="black--text">{{
                $t("components.address_expansion_panel.lookup_address")
              }}</span>
              <v-btn
                @click="
                  useCanadaPost = true;
                  updateValid();
                "
                text
                color="primary"
                class="link mb-1"
              >
                {{ $t("globals.here") }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-row>
    </v-form>
    <v-row v-else-if="fetchedAddress">
      <v-col cols="12" md="6" class="py-0" style="margin-bottom: 20px">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="py-0 font-weight-bold text--secondary">
            {{ $t("components.address_expansion_panel.address") }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="py-0 text--secondary">
            <span class="text-subtitle-1">
              {{ fetchedAddress }}
            </span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="py-0 pb-2">
            <v-btn
              text
              color="primary"
              @click="
                oldFetchedAddress = fetchedAddress;
                fetchedAddress = undefined;
                selectedAddress = null;
                searchResults = [];
              "
            >
              <span class="font-weight-bold">
                {{
                  fetchedAddress === ""
                    ? $t("components.address_expansion_panel.add_address")
                    : $t("components.address_expansion_panel.change_address")
                }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-col cols="12" md="6" class="py-0">
        <div
          style="word-wrap: break-word; white-space: pre-wrap; max-width: 200px"
        >
          
        </div>
        
      </v-col> -->
    </v-row>
    <v-form v-model="valid.contactInfo" v-if="internalMember" class="mt-4 pt-2">
      <v-row class="pa-0 pt-4">
        <v-col cols="12" md="4" class="py-0">
          <no-autocomplete-textfield
            disabled
            outlined
            :label="
              $t(
                'components.registration_completion.contacts_form.email_readonly'
              )
            "
            v-model="internalMember.EmailAddress"
            :rules="[$rules.required, $rules.email]"
            class="required highlight"
          ></no-autocomplete-textfield>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="py-0"
          v-if="checkVisible('Phone Number')"
        >
          <no-autocomplete-textfield
            clearable
            outlined
            :label="
              $t('components.contact_information_expansion_panel.telephone')
            "
            v-model="internalMember.HomePhone"
            :rules="
              checkRequired('Phone Number')
                ? [rules.phone, $rules.required]
                : [rules.phone]
            "
            v-mask="telephoneMask"
            :class="checkRequired('Phone Number') ? 'required' : ''"
          ></no-autocomplete-textfield>
        </v-col>
        <v-col cols="12" md="4" class="py-0" v-if="hasPensionPlan">
          <v-select
            outlined
            :label="
              $t('components.contact_information_expansion_panel.phone_type')
            "
            class="required"
            v-model="internalMember.PreferredPhone"
            :rules="[$rules.required]"
            :items="phoneTypes"
          ></v-select>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import NoAutocompleteTextfield from "@/components/controls/NoAutocompleteTextfield.vue";
import { mapGetters, mapActions } from "vuex";
import { tokens, mask } from "vue-the-mask";
import masker from "vue-the-mask/src/masker";
import countries from "@/countries";
import states from "@/statesUSA";
export default {
  components: { NoAutocompleteTextfield },
  props: {
    saving: Boolean,
    address: Object,
    phone: String,
    formFields: Array,
  },
  directives: { mask },
  data() {
    return {
      internalMember: {
        Address: {},
      },
      valid: {
        contactInfo: false,
        address: false,
      },
      useCanadaPost: true,
      selectedCountry: undefined,
      countries: countries,
      states: states,
      searchValue: undefined,
      searchResults: [],
      selectedAddress: undefined,
      isAddressSearchLoading: false,
      isAddressRetrieveLoading: false,
      oldFetchedAddress: undefined,
      fetchedAddress: undefined,
      canadaPostAddress: undefined,
      telephoneMask: "(###) ### - ####",
      rules: {
        phone: (v) => {
          let val = this.unmask(v, this.telephoneMask);
          return !val || this.$rules.phone(val);
        },
      },
      otherCountry: null,
    };
  },
  computed: {
    ...mapGetters("common", ["enumModels", "identityUser", "settings"]),
    ...mapGetters("auth", ["user"]),
    hasPensionPlan() {
      var hasNeospinMemberId = this.identityUser?.PlanMemberships?.some(
        (o) => o.NeospinMemberId !== null
      );
      return hasNeospinMemberId;
    },
    phoneTypes() {
      const en = [
        {
          text: "Mobile",
          value: "Mobile",
        },
        {
          text: "Home",
          value: "Home",
        },
      ];
      const fr = [
        {
          text: "Mobile",
          value: "Mobile",
        },
        {
          text: "Téléphone",
          value: "Home",
        },
      ];
      return this.$vuetify.lang.current === "fr" ? fr : en;
    },
  },
  watch: {
    internalMember: {
      handler(v) {
        if (
          this.internalMember.HomePhone !== null &&
          this.internalMember.HomePhone !== undefined
        ) {
          v.HomePhone = this.unmask(
            this.internalMember.HomePhone,
            this.telephoneMask
          );
        }
        this.$emit("update-member", v);
      },
      deep: true,
      immediate: true,
    },
    "internalMember.Address": {
      handler(v) {
        if (JSON.stringify(this.internalMember.Address) === JSON.stringify(v)) {
          return;
        }
        this.internalMember.Address.AptUnitSuite =
          this.internalMember.Address.SuiteNumber;
        this.$emit("canada-post-address", this.internalMember.Address);
      },
      deep: true,
    },
    "valid.contactInfo": {
      handler() {
        this.updateValid();
      },
    },
    "valid.address": {
      handler() {
        this.updateValid();
      },
    },
    fetchedAddress: {
      handler() {
        this.updateValid();
      },
      immediate: true,
    },
    searchValue: {
      async handler(v) {
        if (v === null) {
          return;
        }

        if (this.isAddressSearchLoading) {
          return;
        }
        this.isAddressSearchLoading = true;
        try {
          this.searchResults = (
            await this.canadaPostFind({
              payload: {
                Key: this.settings.CanadaPostKey,
                SearchTerm: this.searchValue,
                Country: this.selectedCountry.isOther
                  ? this.otherCountry
                  : this.selectedCountry.code,
                LanguagePreference: this.$vuetify.lang.current.toUpperCase(),
              },
            })
          ).data.Items.map((o) => {
            o.Description = `${o.Text} ${o.Description}`;
            return o;
          });
          // console.log(this.searchResults);
        } catch (error) {
          console.log(error);
        } finally {
          this.isAddressSearchLoading = false;
        }
      },
    },
    selectedAddress: {
      async handler(v) {
        // console.log(v);
        if (v === null) {
          return;
        }
        if (this.isAddressRetrieveLoading) {
          return;
        }
        // console.log(v, this.selectedAddress);
        if (this.selectedAddress.Next === "Find") {
          this.isAddressSearchLoading = true;
          try {
            let payload = {
              Key: this.settings.CanadaPostKey,
              SearchTerm: `${this.selectedAddress.Description}`,
              Country: this.selectedCountry.isOther
                ? this.otherCountry
                : this.selectedCountry.code,
              LanguagePreference: this.$vuetify.lang.current.toUpperCase(),
            };
            if (this.selectedAddress.Id) {
              payload.LastId = this.selectedAddress.Id;
            }
            this.searchResults = (
              await this.canadaPostFind({
                payload: payload,
              })
            ).data.Items.map((o) => {
              o.Description = `${o.Text} ${o.Description}`;
              return o;
            });
            this.searchValue = null;
            this.onFocus();
          } catch (error) {
            console.log(error);
          } finally {
            this.isAddressSearchLoading = false;
          }
        } else if (this.selectedAddress.Next === "Retrieve") {
          this.isAddressRetrieveLoading = true;
          try {
            this.canadaPostAddress = (
              await this.canadaPostRetrieve({
                payload: {
                  Key: this.settings.CanadaPostKey,
                  Id: this.selectedAddress.Id,
                  LanguagePreference: this.$vuetify.lang.current.toUpperCase(),
                },
              })
            ).data.Items;
            this.fetchedAddress = this.canadaPostAddress[0].Label;
            // console.log(this.fetchedAddress, this.canadaPostAddress);
            this.$emit("canada-post-address", this.createMemberAddress());
          } catch (error) {
            console.log(error);
          } finally {
            this.isAddressRetrieveLoading = false;
          }
        }
      },
    },
    selectedCountry: {
      handler(v) {
        this.internalMember.Address = JSON.parse(JSON.stringify(this.address));
        if (v?.textEn?.toUpperCase() === "CANADA") {
          this.category = "Civic";
          this.internalMember.Address.Country = this.enumModels.Country.find(
            (o) => o.textEn.toUpperCase() === "CANADA"
          )?.value;
          this.internalMember.Address.OtherCountry = null;
          this.internalMember.Address.OtherProvince = null;
          this.internalMember.Address.OtherPostalCode = null;
          this.internalMember.Address.AddressLine = null;
          this.internalMember.Address.Province =
            this.internalMember.Address.Province?.toUpperCase();
        }
        if (v?.textEn?.toUpperCase() === "UNITED STATES") {
          this.internalMember.Address.OtherCountry = "United States";
          this.otherCountry = "United States";
          this.category = "Non-Civic US";
          this.internalMember.Address.Country = null;
          this.internalMember.Address.Province = null;
          this.internalMember.Address.PostalCode = null;
        }
        if (v?.textEn?.toUpperCase() === "OTHER") {
          if (this.internalMember.Address.OtherCountry === "United States") {
            this.internalMember.Address.OtherCountry = null;
          }
          this.category = "Non-Civic International";
          this.internalMember.Address.Country = null;
          this.internalMember.Address.Province = null;
          this.internalMember.Address.PostalCode = null;
        }
        this.searchResults = [];
      },
    },
  },
  mounted() {
    this.internalMember.EmailAddress = this.identityUser.Email;
    if (this.address) {
      this.fetchedAddress = this.$helpers.formatDrawbridgeAddress(this.address);
      this.internalMember.Address = JSON.parse(JSON.stringify(this.address));
    }
    if (this.phone) {
      this.internalMember.HomePhone = this.phone;
    } else this.internalMember.HomePhone = null;
  },
  methods: {
    ...mapActions("data", ["canadaPostFind", "canadaPostRetrieve"]),
    provinceCode(provinceId) {
      const enumValue = this.enumModels.Province.filter(
        (o) => o.value === provinceId
      );
      return enumValue[0].textEn;
    },
    createMemberAddress() {
      let address = this.canadaPostAddress[0];
      console.log("address to parse", address);
      const lines = address.Label.split(/\r?\n/);
      const postalCode = address.PostalCode ? address.PostalCode : "";

      if (
        this.selectedCountry.isOther ||
        this.selectedCountry?.textEn.toUpperCase() === "UNITED STATES"
      ) {
        const addressLine = lines.length > 0 ? lines[0] : null;
        const addressLine2 = lines.length > 1 ? lines[1] : null;
        const line2 = lines.length > 2 ? lines[2] : "";
        const province = address.ProvinceName;
        let addressLine3 = "";
        if (
          line2 &&
          line2.toUpperCase().includes(address?.CountryName?.toUpperCase())
        ) {
          addressLine3 = line2
            .toUpperCase()
            .replace(address?.CountryName?.toUpperCase(), "");
        } else {
          addressLine3 = line2;
        }

        if (
          !addressLine.includes(postalCode) &&
          !addressLine2.includes(postalCode) &&
          !addressLine3.includes(postalCode)
        ) {
          addressLine3 = addressLine3
            ? addressLine3 + " " + postalCode
            : postalCode;
        }

        if (
          !addressLine?.toUpperCase().includes(province?.toUpperCase()) &&
          !addressLine2?.toUpperCase().includes(province?.toUpperCase()) &&
          !addressLine3?.toUpperCase().includes(province?.toUpperCase())
        ) {
          addressLine3 = addressLine3
            ? addressLine3 + " " + province
            : province;
        }
        this.internalMember.Address = {
          Category:
            this.selectedCountry?.code === "USA"
              ? "Non-Civic US"
              : "Non-Civic International",
          StreetNumber: address.BuildingNumber,
          StreetName: address.Street,
          City: address.City,
          State:
            this.selectedCountry?.code === "USA" ? address.ProvinceName : null,
          Country: null,
          Province: null,
          OtherProvince: address.ProvinceName,
          OtherPostalCode: address.PostalCode,
          OtherCountry: this.otherCountry,
          SuiteNumber: address.SubBuilding,
          AptUnitSuite: address.SubBuilding,
          AddressLine: addressLine,
          AddressLine2: addressLine2,
          AddressLine3: addressLine3,
        };
      } else {
        const province = this.enumModels.Province.filter(
          (o) => o.code === address.ProvinceCode
        );
        const { streetType, streetDirection, streetName } =
          this.getStreetTypeAndDirection(address);
        this.internalMember.Address = {
          Category: "Civic",
          StreetNumber: address.BuildingNumber,
          StreetName: address.Street,
          StreetNameOnly: streetName,
          StreetDirection: streetDirection,
          StreetType: streetType,
          City: address.City,
          Province:
            province.length > 0 ? province[0].value : address.ProvinceCode,
          PostalCode: address.PostalCode,
          Country: this.selectedCountry?.value,
          SuiteNumber: address.SubBuilding,
          AptUnitSuite: address.SubBuilding,
        };
      }
      return this.internalMember.Address;
    },
    getStreetTypeAndDirection(address) {
      const streets = this.$helpers.parseStreetByCountryAndLanguage(
        address,
        this.selectedCountry
      );
      const streetType = streets[0]?.streetType;
      const { streetName, streetDirection } = this.$helpers.cleanUpStreetName(
        address?.Street
      );
      console.log(
        "cleaned up streetName:",
        streetName,
        "streetType:",
        streetType,
        "streetDirection:",
        streetDirection
      );
      return { streetName, streetType, streetDirection };
    },
    unmask(v, mask) {
      // console.log(v, mask);
      if (v) {
        return masker(v, mask, false, tokens);
      }
      return v;
    },
    onFocus() {
      this.$refs.addressList.isMenuActive = true;
    },
    formatPhone(v) {
      return masker(v, this.telephoneMask, true, tokens);
    },
    checkVisible(field) {
      const result = this.formFields?.find((o) => o.Text === field);
      return result ? result.OnCreate === "Edit" : false;
    },
    checkRequired(field) {
      const result = this.formFields?.find((o) => o.Text === field);
      return result ? !result.IsOptional : false;
    },
    updateValid() {
      let validAddress = false;
      if (this.useCanadaPost) {
        validAddress =
          this.valid.contactInfo && this.valid.address && !!this.fetchedAddress;
      } else {
        validAddress = this.valid.contactInfo && this.valid.address;
      }
      this.$emit("update-valid", validAddress);
    },
  },
};
</script>
<style scoped>
.camera-btn {
  right: 25px;
  top: 25px;
  position: relative;
}
.avatar {
  border: 3px white solid;
}
.v-input--is-disabled >>> label {
  color: rgba(0, 0, 0, 0.87) !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.v-tooltip__content {
  max-width: 150px !important;
}
.note-text {
  line-height: 10px !important;
}
.link {
  text-decoration: underline;
  text-transform: lowercase !important;
  min-width: 0 !important;
}
</style>
